import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";

// styles
import contactPageStyle from "assets/jss/material-kit-react/views/defaultPage.jsx";

// Sections for this page
import SectionContact from "./Sections/sectionContact.jsx";

const dashboardRoutes = [];

class ContactPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Finwe Ltd."
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 150,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/default_background.jpg")} small>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 className={classes.title}>Contact Us</h1>
                <h4>
                  Looking for an experienced team to build a video app, develop
                  an algorithm, or setup a 360° timelapse or live stream?
                  Got a question about our products? We'd love to hear about you.
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionContact />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(contactPageStyle)(ContactPage);
